import {
  Box, Container, Flex, Image, Menu, MenuItem, MenuList, Spacer, Tab, TabIndicator, TabList, Tabs, Text,
} from '@chakra-ui/react';
import { useMemo } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { Profile } from './Profile';
import { IClinicModel } from 'api/Models';
import SettingsIcon from 'assets/icons/settings.svg';
import Logo from 'assets/logos/light-logo.svg';
import useAxios from 'hooks/useAxios';
import useUserContext from 'hooks/useUserContext';
import { borderRadius, skylineColor } from 'themes/constants';

export default function Header(): JSX.Element {
  const {
    logout,
    user,
  } = useUserContext();
  const [clinicRequest] = useAxios<IClinicModel>('/api/clinic');
  const location = useLocation().pathname;

  const avatar = `https://ui-avatars.com/api/?name=${user!.firstName}${user!.lastName}&format=svg&background=random`;

  const tabIndex = useMemo(() => {
    if (location.startsWith('/archives')) return 1;
    return 0;
  }, [location]);

  return (
    <Container as={Flex} px='22px' pb={['100px', 0]} mt='18px' minW='350px' maxW='1920px' alignItems='center'>
      <Box as={RouterLink} to='/'>
        <Image width='159px' mr='50px' src={Logo} />
      </Box>
      <Text fontSize='xl' fontWeight='bold' mr='50px'>{clinicRequest.data?.name}</Text>
      <Tabs
        index={tabIndex}
        position='relative'
        variant='unstyled'
        isLazy
        lazyBehavior='unmount'
      >
        <TabList
          gap='50px'
        >
          <Tab
            as={RouterLink}
            px='0px'
            _hover={{ color: 'gray.400' }}
            _selected={{ color: skylineColor }}
            to='/studies'
            fontWeight='600'
            fontSize='16px'
          >
            Studies
          </Tab>
        </TabList>
        <TabIndicator
          position='relative'
          mt='0px'
          height='0px'
          bg={skylineColor}
          _before={{
            content: '""',
            position: 'absolute',
            top: 'auto',
            left: '50%',
            transform: 'translateX(-50%)',
            height: '5px',
            width: '48px !important',
            bg: skylineColor,
            opacity: '1',
            borderRadius,
            transformOrigin: 'left',
          }}
        />
      </Tabs>
      <Spacer />
      <Menu isLazy>
        <Profile
          displayName={`${user?.firstName} ${user?.lastName}`}
          avatarSrc={avatar}
          email={user?.email}
        />
        <Box as={RouterLink} to='/settings' mx='10px'>
          <img src={SettingsIcon} width='24px' height='24px' />
        </Box>
        <MenuList textStyle='paragraphReg'>
          <MenuItem as={RouterLink} to='/settings'>Settings</MenuItem>
          <MenuItem as={RouterLink} to='/' onClick={() => logout()}>Logout</MenuItem>
        </MenuList>
      </Menu>
    </Container>
  );
}
