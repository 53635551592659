import {
  Flex,
  Text,
} from '@chakra-ui/react';
import axios from 'axios';
import { useState } from 'react';
import { Else, If, Then } from 'react-if';

import GreenCheckmark from 'assets/icons/green-checkmark.svg';
import RedExclamation from 'assets/icons/red-exclamation.svg';
import { AsyncWrapper } from 'components/AsyncWrapper';
import AsyncButton from 'components/form/AsyncButton';
import useAxios from 'hooks/useAxios';
import useUserContext from 'hooks/useUserContext';

export default function Billing(): JSX.Element {
  const { user } = useUserContext();
  // for clinics that are not don't have a subscription, we will assume it's managed by tenant default
  const isSubscriptionManagedByTenant = user?.clinic?.subscription?.managedByTenant ?? true;
  const [paymentMethodRequest] = useAxios<boolean>('/api/billing/check-payment-method');
  const [signedUp, setSignedUp] = useState(!!user?.clinic?.usageBasedSubscriptionId);
  const isPaymentMethod = paymentMethodRequest.data;

  async function handleRedirectToCustomerPortal(pathInCheckoutSession?: string | null): Promise<void> {
    const { data: stripeCheckoutSession } = await axios.post<{ url: string }>('/api/billing/customer-portal');
    window.open(`${stripeCheckoutSession.url}${pathInCheckoutSession || ''}`, '_blank');
  }

  async function handleSignUpClick(): Promise<void> {
    await axios.post('/api/billing/usage-based-subscription');
    setSignedUp(true);
    await handleRedirectToCustomerPortal('/payment-methods');
  }

  return (
    <AsyncWrapper requests={[paymentMethodRequest]}>
      <Flex gap='10px' flexDirection='column' mt='25px'>
        <Text fontWeight='bold' fontSize='18px'>Skyline Subscription</Text>
        <Flex mb='50px' width='894px' height='100px' justifyContent='space-between' alignItems='center' border='1px solid #718096' borderRadius='12px' padding='20px'>
          <Text>{isSubscriptionManagedByTenant ? 'Subscription is managed by your dealer' : 'Manage my subscription'}</Text>
          {!isSubscriptionManagedByTenant && (
            <AsyncButton
              onClick={() => handleRedirectToCustomerPortal()}
              width='200px'
            >
              Manage subscription
            </AsyncButton>
          )}
        </Flex>
        <Flex flexDirection='column'>
          <Text fontWeight='bold' fontSize='18px' mb='10px'>Usage Based Billing</Text>
          <Text mb='20px'>Sign up for usage charge subscription</Text>
          <Flex width='894px' height='100px' justifyContent='space-between' alignItems='center' border='1px solid #718096' borderRadius='12px' padding='20px'>
            <If condition={signedUp}>
              <Then>
                <Flex gap='7px' alignItems='center' justifyContent='space-between' width='100%'>
                  <Flex gap='7px'>
                    <img src={isPaymentMethod ? GreenCheckmark : RedExclamation} alt={isPaymentMethod ? 'Payment method exists' : 'Payment method is missing'} />
                    <Text>{isPaymentMethod ? 'Billing info is up to date' : 'Billing info is invalid or missing, please add a payment'}</Text>
                  </Flex>
                  <Flex>
                    <AsyncButton onClick={() => handleRedirectToCustomerPortal()}>
                      Manage billing
                    </AsyncButton>
                  </Flex>
                </Flex>
              </Then>
              <Else>
                <Flex gap='7px' alignItems='center' justifyContent='space-between' width='100%'>
                  <Flex width='100%' gap='7px'>
                    <img src={RedExclamation} alt='You are not signed up' />
                    <Text>You are not signed up</Text>
                  </Flex>
                  <Flex>
                    <AsyncButton width='200px' onClick={handleSignUpClick}>
                      Sign up
                    </AsyncButton>
                  </Flex>
                </Flex>
              </Else>
            </If>
          </Flex>
        </Flex>
      </Flex>
    </AsyncWrapper>
  );
}
