import {
  Box,
  Flex, Img, Spinner, Text,
} from '@chakra-ui/react';
import { useMemo, useState } from 'react';
import { When } from 'react-if';

import { IImageModel } from 'api/Models';

const NUMBER_OF_IMAGES = 2;

export default function ImagesPreview({ images }: { images?: IImageModel[] }): JSX.Element {
  const [loaded, setLoaded] = useState<{ [key: number]: boolean }>({});
  const imagesPreview = useMemo(() => {
    if (!images) return [];
    return images.slice(0, NUMBER_OF_IMAGES);
  }, [images]);

  const leftAmountOfImages = images && images.length > NUMBER_OF_IMAGES ? `+ ${images.length - NUMBER_OF_IMAGES}` : '';
  const handleImageLoad = (id: number): void => {
    setLoaded((prev) => ({ ...prev, [id]: true }));
  };

  return (
    <Flex height='80px' gap='10px'>
      {imagesPreview.map((image) => (
        <Flex key={image.id} height='80px' alignItems='center' position='relative'>
          <Img
            src={`/api/thumbnail/${image.id}.png?h=80`}
            height='80px'
            borderRadius='2px'
            onLoad={() => handleImageLoad(image.id)}
            style={{ display: loaded[image.id] ? 'block' : 'none' }}
          />
          <When condition={!loaded[image.id]}>
            <Box width='65px'>
              <Spinner />
            </Box>
          </When>
        </Flex>
      ))}
      <Text fontSize='lg' display='flex' alignItems='center'>{leftAmountOfImages}</Text>
    </Flex>
  );
}
