import {
  Navigate, Outlet, useLocation,
} from 'react-router-dom';

import useUserContext from 'hooks/useUserContext';

export default function PrivateRoute(): JSX.Element {
  const { isLoggedIn } = useUserContext();
  const { pathname } = useLocation();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  if (!isLoggedIn) return <Navigate to={`/login?redirectTo=${pathname}&${searchParams.toString()}`} />;

  return <Outlet />;
}
