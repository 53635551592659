import { tableAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { borderRadius, skylineColor, stylisticFont } from './constants';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tableAnatomy.keys);

const simple = definePartsStyle(() => ({
  table: {
    borderSpacing: '0px 10px',
    borderCollapse: 'separate',
  },
  thead: {
    backgroundColor: skylineColor,
  },
  tbody: {
    tr: {
      'borderRadius': borderRadius,
      ':hover td': {
        backgroundColor: 'gray.600',
      },
    },
    td: {
      'height': '46px',
      'paddingTop': 0,
      'paddingBottom': 0,
      'fontWeight': 500,
      'fontSize': '14px',
      'lineHeight': '20px',
      'borderBottom': 'none',
      'overflow': 'hidden',
      ':last-of-type': {
        borderTopRightRadius: borderRadius,
        borderBottomRightRadius: borderRadius,
      },
      ':first-of-type': {
        borderTopLeftRadius: borderRadius,
        borderBottomLeftRadius: borderRadius,
      },
    },
  },
  th: {
    fontFamily: { stylisticFont },
    fontWeight: 600,
    lineHeight: '22px',
    color: 'white',
    textTransform: 'capitalize',
    fontSize: '14px',
    height: '60px',
    py: '13px',
    borderBottom: 'none',
    position: 'relative',
    overflow: 'hidden',

    _first: {
      'borderTopLeftRadius': borderRadius,
      'borderBottomLeftRadius': borderRadius,
      '::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        width: '8px',
        backgroundColor: skylineColor,
      },
    },
    _last: {
      borderTopRightRadius: borderRadius,
      borderBottomRightRadius: borderRadius,
    },
  },
}));

export const tableTheme = defineMultiStyleConfig({
  variants: { simple },
  defaultProps: {
    colorScheme: 'none',
    variant: 'simple',
  },
});
