import axios from 'axios';
import { useEffect, useRef } from 'react';
import { useAsyncFn, useLogger } from 'react-use';

export interface IUseAxiosRequest<T = any> {
  data?: T,
  headers?: Record<string, any>,
  loading: boolean,
  error?: Error,
}

export type FetchReturn<TResponse> = () => Promise<{
  data: TResponse,
  headers: Record<string, any>,
}>;

export default function useAxios<TResponse = any, TBody = any>(url: string, manual = false)
  : [IUseAxiosRequest<TResponse>, FetchReturn<TResponse>] {
  const abortController = useRef(new AbortController());

  useLogger(`useAxios - ${url} - ${manual ? 'manual' : 'auto'}`);

  const [state, fetch] = useAsyncFn(async () => {
    const response = await axios<TResponse>({ url, signal: abortController.current.signal });
    return {
      data: response.data,
      headers: response.headers,
    };
  }, [url]);

  // useUnmount(() => {
  //   abortController.current.abort('Unmounting');
  //   abortController.current = new AbortController();
  // });

  // // if (state.error) handleError(state.error);
  useEffect(() => {
    if (!manual) void fetch();
    return () => {
      abortController.current.abort('Unmounting');
      abortController.current = new AbortController();
    };
  }, [fetch, manual]);

  // if not a manual request, loading is true until request dispatch
  const loading = state.loading === true || (!manual && state.value === undefined && state.error === undefined);

  return [{
    data: state.value?.data,
    headers: state.value?.headers,
    loading,
    error: state.error,
  }, fetch];
}
