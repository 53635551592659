import {
  Tab, TabList, TabPanel, TabPanels, Tabs,
} from '@chakra-ui/react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useGate } from 'statsig-react';

import Billing from './Billing';
import ChangePassword from './ChangePassword';
import DicomServersList from './dicom/DicomServersList';
import EditClinic from './EditClinic';
import EditProfile from './EditProfile';
import PacsInfo from './PacsInfo';
import { StatsigGates } from 'StatsigGates';

const tabMap: Record<string, number> = {
  'my-profile': 0,
  'my-clinic': 1,
  'pacs-info': 2,
  'dicom-servers': 3,
  'change-password': 4,
  'billing': 5,
};
function SettingsPage(): JSX.Element {
  const { tabName } = useParams<{ tabName: string }>();
  const showUsageBilling = useGate(StatsigGates.USAGE_BILLED_BILLING).value;
  return (
    <Tabs
      index={tabMap[tabName || 'my-profile']}
    >
      <TabList>
        <Link to='/settings/my-profile'>
          <Tab>My Profile</Tab>
        </Link>
        <Link to='/settings/my-clinic'>
          <Tab>My Clinic</Tab>
        </Link>
        <Link to='/settings/pacs-info'>
          <Tab>PACS Info</Tab>
        </Link>
        <Link to='/settings/dicom-servers'>
          <Tab>Dicom Servers</Tab>
        </Link>
        <Link to='/settings/change-password'>
          <Tab>Change Password</Tab>
        </Link>
        {showUsageBilling && (
          <Link to='/settings/billing'>
            <Tab>Billing</Tab>
          </Link>
        )}
      </TabList>
      <TabPanels>
        <TabPanel>
          <EditProfile />
        </TabPanel>
        <TabPanel>
          <EditClinic />
        </TabPanel>
        <TabPanel>
          <PacsInfo />
        </TabPanel>
        <TabPanel>
          <DicomServersList />
        </TabPanel>
        <TabPanel>
          <ChangePassword />
        </TabPanel>
        {showUsageBilling && (
          <TabPanel>
            <Billing />
          </TabPanel>
        )}
      </TabPanels>
    </Tabs>
  );
}

export default SettingsPage;
