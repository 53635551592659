import {
  Button, ThemingProps, chakra, useStyleConfig,
} from '@chakra-ui/react';
import {
  ButtonHTMLAttributes, ReactNode, useEffect, useState,
} from 'react';
import { FieldValues, FormState } from 'react-hook-form';
import { useMountedState } from 'react-use';

interface IAsyncButton<T extends FieldValues> extends ButtonHTMLAttributes<HTMLButtonElement>, ThemingProps<'Button'> {
  formState?: FormState<T>;
  onClick?(e?: React.MouseEvent<HTMLButtonElement>): Promise<void> | void;
  children: ReactNode; resetAfterSubmit?: boolean;
  isDisabled?: boolean;
}

function AsyncButton<T extends FieldValues>({
  formState, children, resetAfterSubmit = true, onClick, ...props
}: IAsyncButton<T>): JSX.Element {
  const {
    variant, size, colorScheme, disabled,
  } = props;
  const styles = useStyleConfig('Button', { variant, size, colorScheme });
  const isMounted = useMountedState();
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (formState) {
      const isSubmitted = formState.isSubmitSuccessful && formState.submitCount !== 0;
      setLoading(formState.isSubmitting || (!resetAfterSubmit && isSubmitted));
    }
  }, [formState, onClick, resetAfterSubmit]);

  async function handleAsyncClick(event?: React.MouseEvent<HTMLButtonElement>): Promise<void> {
    if (!onClick) return;
    try {
      setLoading(true);
      await onClick(event);
    } catch (e) {
      setLoading(false);
      throw e;
    } finally {
      if (resetAfterSubmit && isMounted()) setLoading(false);
    }
  }

  return (
    <Button
      {...props}
      __css={styles}
      onClick={handleAsyncClick}
      isLoading={isLoading}
      isDisabled={disabled}
    >
      {children}
    </Button>
  );
}

export default chakra(AsyncButton);
