import {
  Flex, Image,
} from '@chakra-ui/react';
import { UseFormSetError } from 'react-hook-form';
import { Navigate } from 'react-router-dom';
import { useSearchParam } from 'react-use';

import { CredentialsForm, ILogin } from './CredentialsForm';
import Logo from 'assets/logos/light-logo.svg';
import useUserContext from 'hooks/useUserContext';
import { logError } from 'utils/error';

export default function LoginPage(): JSX.Element {
  const redirectTo = useSearchParam('redirectTo') || '/';
  const requestedEmail = useSearchParam('email');
  const { isLoggedIn, login } = useUserContext();

  const redirectLocation = `${redirectTo}${requestedEmail ? `?email=${requestedEmail}` : ''}`;

  if (isLoggedIn) {
    console.log(`isLoggedIn. redirecting to ${redirectTo}`);
    return <Navigate to={redirectLocation} />;
  }

  const onPasswordSubmit = async (data: ILogin, setError: UseFormSetError<ILogin>): Promise<void> => {
    const { email, password } = data;
    try {
      await login(email, password);
    } catch (e: any) {
      setError('password', { type: 'manual', message: e.response?.data.message });
      logError(e);
    }
  };

  return (
    <Flex direction='column' align='center' w='300px' mx='auto'>
      <Image h='65px' mb='50px' src={Logo} />
      <CredentialsForm submitCallback={onPasswordSubmit} />
    </Flex>
  );
}
