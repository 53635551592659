import { Container, Flex, Spacer } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { When } from 'react-if';
import { useLogger, useNetworkState } from 'react-use';
import { StatsigProvider } from 'statsig-react';

import Footer from 'components/Footer';
import Header from 'components/Header';
import { OfflineAlert } from 'components/OfflineAlert';
import { env } from 'env';
import useUserContext from 'hooks/useUserContext';

interface IPageTemplate {
  children: ReactNode;
}

export default function PageTemplate({ children }: IPageTemplate): JSX.Element {
  const {
    user, isLoggedIn,
  } = useUserContext();
  const { online } = useNetworkState();

  useLogger('PageTemplate');
  return (
    <StatsigProvider
      sdkKey={env.REACT_APP_STATSIG_KEY}
      user={{
        userID: user?.id,
        email: user?.email,
        userAgent: navigator.userAgent,
        custom: {
          isLoggedIn,
          clinicId: user?.clinicId,
        },
      }}
      waitForInitialization
    >
      <Flex direction='column' minH='100vh'>
        <When condition={!online}>
          <OfflineAlert />
        </When>
        <When condition={isLoggedIn}>
          <Header />
        </When>
        <Container
          as='main'
          minW='350px'
          maxW='1920px'
          px='20px'
          marginTop='60px'
          marginBottom='50px'
          mb='50px'
        >
          {children}
        </Container>
        <Spacer />
        <Footer />
      </Flex>
    </StatsigProvider>
  );
}
