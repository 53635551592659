import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Box, Button, ButtonGroup } from '@chakra-ui/react';

import { borderRadius } from 'themes/constants';

export type PaginationParams = {
  pageSize: number;
  page: number;
};

type PaginationCallBack = React.Dispatch<React.SetStateAction<PaginationParams>>;

export function Pagination({
  pagination,
  setPagination,
  totalRecords,
}: {
  pagination: PaginationParams;
  setPagination: PaginationCallBack;
  totalRecords?: number;
}): JSX.Element {
  if (!totalRecords) {
    return <></>;
  }

  const noNextPage = (pagination.page * pagination.pageSize) >= totalRecords;
  const noPreviousPage = pagination.page <= 1;

  function getNextPage(): void {
    if (noNextPage) return;
    setPagination((prev) => ({
      ...prev,
      page: prev.page + 1,
    }));
  }

  function getPreviousPage(): void {
    if (noPreviousPage) return;
    setPagination((prev) => ({
      ...prev,
      page: prev.page - 1,
    }));
  }

  return (
    <Box mt='50px'>
      <ButtonGroup
        isAttached
        variant='outline'
        borderRadius={borderRadius}
        float='right'
      >
        <Button
          leftIcon={<ChevronLeftIcon />}
          onClick={getPreviousPage}
          isDisabled={noPreviousPage}
          p='17px'
          borderRight='2px'
          borderColor='gray.400'
        >
          Back
        </Button>
        <Button
          rightIcon={<ChevronRightIcon />}
          onClick={getNextPage}
          isDisabled={noNextPage}
          p='17px'
          borderLeft='2px'
          borderColor='gray.400'
        >
          Next
        </Button>
      </ButtonGroup>
    </Box>
  );
}
