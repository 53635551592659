import {
  Button, Heading, VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { When } from 'react-if';
import ReactJson from 'react-json-view';
import { Link as RouterLink } from 'react-router-dom';
import { v4 } from 'uuid';

import { DevOverrideKeys } from './devOverrides';
import { DevToggle } from './DevToggle';
import useUserContext from 'hooks/useUserContext';
import { StudioError } from 'StudioError';

export default function DevPage(): JSX.Element {
  const [storageItems, setStorageItems] = useState({});
  const [appInfo, setAppInfo] = useState({});
  const userState = useUserContext();

  function throwError(): void {
    // throw new Error('Test Error');
    throw new StudioError('DEV_ERROR', `${v4()}/${v4()}/awdawd_asawdadw${v4()} and ${v4()}`);
  }

  function consoleError(): void {
    console.error('DEV_ERROR', `${v4()}/${v4()}/awdawd_asawdadw${v4()} and ${v4()}`);
  }

  return (
    <>
      <Heading as='h3'>Debug</Heading>
      <DevToggle name={DevOverrideKeys.STRIPE} options={['true']} />
      <ReactJson
        collapsed
        src={appInfo}
        name='appInfo'
      />
      <ReactJson
        collapsed
        src={userState || {}}
        name='userState'
      />
      <ReactJson
        collapsed
        src={import.meta.env}
        name='env'
      />
      <When condition={!!storageItems}>
        <ReactJson
          collapsed
          src={storageItems}
          name='storageItems'
        />
      </When>
      <VStack mt='20px'>
        <Button onClick={throwError}>Throw Exception</Button>
        <Button onClick={consoleError}>Console Error</Button>
        <Button as={RouterLink} to='/session-sheet/A54011C1-ECA3-4834-81F9-95132BFD2771'>Session Sheet Page</Button>
        <Button as={RouterLink} to='/projects/C4BB1E5B-BB0C-4807-BCCA-1690CBD13AEB/work-orders/FDFF75BF-1D51-4465-AE3F-8B629378FA32'>Work Order Page</Button>
        <Button as={RouterLink} to='/ct/projects'>Projects</Button>
      </VStack>
    </>
  );
}
