import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';

import useAxios, { IUseAxiosRequest } from './useAxios';
import { Pagination, PaginationParams } from 'components/table/Pagination';

interface InfiniteScrollingReturn<T> {
  request?: IUseAxiosRequest<PagedResponse<T>>;
  PaginationControl: JSX.Element;
  refetchRequest: () => void;
}

interface PagedResponse<T> {
  page: number;
  pageSize: number;
  totalRecords: number;
  records: T[];
}

export function usePagedData<T>({ path }: { path: string }): InfiniteScrollingReturn<T> {
  const [pagination, setPagination] = useState<PaginationParams>({
    pageSize: 20,
    page: 1,
  });

  const { search, pathname } = useLocation();

  const queryString = useMemo(() => new URLSearchParams(search).get('search'), [search]);

  useEffect(() => setPagination({ pageSize: 20, page: 1 }), [queryString]);

  let url = `${path}?page=${pagination.page}&pageSize=${pagination.pageSize}`;
  if (queryString) url = url.concat(`&search=${queryString}`);

  const [request, refetchRequest] = useAxios<PagedResponse<T>>(url);

  const paginationControl = useMemo(() => Pagination({ pagination, setPagination, totalRecords: request.data?.totalRecords }), [pagination, request.data]);

  return {
    request,
    PaginationControl: paginationControl,
    refetchRequest: () => void refetchRequest(),
  };
}
