import {
  Flex, Img, Modal, ModalBody, ModalCloseButton, ModalContent, ModalContentProps, ModalHeader, ModalOverlay, ModalProps, Text,
} from '@chakra-ui/react';
import { When } from 'react-if';

import { borderRadius } from 'themes/constants';

const contentStyle: ModalContentProps = {
  p: '50px',
  borderRadius,
  borderColor: 'gray.400',
  borderWidth: '1px',
  bg: 'gray.800',
};

export type ModalFrameProps = {
  header: JSX.Element,
  hasCloseButton?: boolean,
  style?: {
    content?: ModalContentProps,
    header?: ModalContentProps,
    body?: ModalContentProps,
  }
  children: React.ReactNode
} & ModalProps;

export function BasicModalHeader({ icon, title }: { icon?: string, title: string }): JSX.Element {
  return (
    <>
      {icon && <Img height='20px' width='20px' src={icon} />}
      <Text>{title}</Text>
    </>
  );
}

export function ModalFrame({
  header,
  hasCloseButton = false,
  style,
  children,
  isOpen,
  isCentered,
  onClose,
  size,
}: ModalFrameProps): JSX.Element {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered={isCentered}
      size={size}
    >
      <ModalOverlay />
      <ModalContent
        {...contentStyle}
        {...style?.content}
      >
        <ModalHeader p='0' mb='20px' {...style?.header}>
          <Flex alignItems='center' gap='10px'>
            {header}
          </Flex>
          <When condition={hasCloseButton}>
            <ModalCloseButton />
          </When>
          {/* test */}
        </ModalHeader>
        <ModalBody p='0' {...style?.body}>
          {children}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
