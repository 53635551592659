import { useState } from 'react';

interface ModalState<T> {
  isOpen: boolean;
  data: T | undefined;
}

interface ModalActions<T> {
  onOpen: () => void;
  onClose: () => void;
  handleEdit: (input: T) => void;
  handleDelete: (input: T) => void;
}

type UseModal<T> = [ ModalState<T>, ModalActions<T> ];

export function useModal<T>({
  onOpen, onClose,
}: { onOpen?: () => void, onClose?: () => void
}): UseModal<T> {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<T | undefined>(undefined);

  const onOpenModal = (): void => {
    if (onOpen) onOpen();
    setIsOpen(true);
  };

  const onCloseModal = (): void => {
    if (onClose) onClose();
    setData(undefined);
    setIsOpen(false);
  };

  const handleEdit = (input: T): void => {
    setData(input);
    onOpenModal();
  };

  const handleDelete = (input: T): void => {
    setData(input);
    onOpenModal();
  };

  return [
    { isOpen, data },
    {
      onOpen: onOpenModal,
      onClose: onCloseModal,
      handleEdit,
      handleDelete,
    },
  ];
}
