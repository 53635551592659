import {
  IconButton,
  Img,
  Menu, MenuButton, MenuItem, MenuList,
} from '@chakra-ui/react';

import ThreeEllipseIcon from 'assets/icons/three-ellipses.svg';

export function ActionMenu(actions: { label: string, action: (data: any) => void }[]): JSX.Element {
  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label='actions'
        icon={<Img src={ThreeEllipseIcon} />}
        variant='ghost'
        w='60px'
        colorScheme='orange'
      />
      <MenuList>
        {Object.values(actions).map((action) => <MenuItem key={action.label} onClick={action.action}>{action.label}</MenuItem>)}
      </MenuList>
    </Menu>
  );
}
