import { Button } from '@chakra-ui/react';
import { isAxiosError } from 'axios';
import { useEffect, useMemo, useRef } from 'react';
import { FallbackProps } from 'react-error-boundary';
import ReactJson from 'react-json-view';
import { useLocation } from 'react-router';

import ErrorPage from './ErrorPage';
import PageTemplate from '../page-template/PageTemplate';
import { getAxiosErrorStatus } from 'api/configureAxios';
import { Sentry } from 'configureSentry';

function ErrorFallback({ error, resetErrorBoundary }: FallbackProps): JSX.Element {
  Sentry.captureException(error);
  const { pathname } = useLocation();
  const originalPathname = useRef(pathname);

  useEffect(() => {
    if (pathname !== originalPathname.current) resetErrorBoundary();
  }, [pathname, resetErrorBoundary]);

  const devErrorMessage = useMemo(() => {
    if (isAxiosError(error)) {
      return `${getAxiosErrorStatus(error)}: ${error.message}`;
    }
    return `${error.name}: ${error.message}`;
  }, [error]);

  return (
    <PageTemplate>
      <ErrorPage>
        <p>{devErrorMessage}</p>
        <ReactJson src={error} />
        <Button onClick={resetErrorBoundary}>
          Try again
        </Button>
      </ErrorPage>
    </PageTemplate>
  );
}

export default ErrorFallback;
