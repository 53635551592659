import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Image,
  Input,
  Link,
  Text,
  useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import { useSearchParam } from 'react-use';
import * as z from 'zod';

import RoundedKeyIcon from 'assets/icons/rounded-key.svg';
import AsyncButton from 'components/form/AsyncButton';

interface IResetPasswordForm {
  newPassword: string;
  confirmPassword: string;
}

const resetPasswordSchema = z
  .object({
    newPassword: z
      .string()
      .trim()
      .min(8)
      .regex(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/i,
        'Must contain at least one digit, one uppercase character and one lowercase character',
      ),
    confirmPassword: z.string().trim()
      .min(1, 'Confirm password required'),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: 'Password field must match confirm password field',
    path: ['confirmPassword'],
  });

export default function ResetPassword(): JSX.Element {
  const token = useSearchParam('token');
  const emailParam = useSearchParam('email');

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IResetPasswordForm>({
    resolver: zodResolver(resetPasswordSchema),
  });

  const toast = useToast();

  async function onSubmit(data: IResetPasswordForm): Promise<void> {
    await axios.post('/api/auth/reset-password', {
      NewPassword: data.newPassword,
      Token: token,
      Email: emailParam,
    });

    toast({
      title: 'Success',
      description: 'Password reset successfully',
      status: 'success',
      duration: 5000,
      position: 'top',
      isClosable: true,
    });

    window.location.href = '/login';
  }
  return (
    <Flex
      width={['320px', '400px']}
      direction='column'
      mx='auto'
      align='center'
    >
      <Image src={RoundedKeyIcon} h='70px' />
      <Flex direction='column' align='center' mt='35px' mb='50px' gap='25px'>
        <Heading fontSize='24px' fontWeight='600px'>
          New password
        </Heading>
        <Text fontSize='16px' lineHeight='20px' fontWeight='400'>
          Create a new password for your account.
        </Text>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl isInvalid={!!errors.newPassword?.message}>
            <FormLabel>New Password</FormLabel>
            <Input type='password' {...register('newPassword')} />
            <FormErrorMessage>{errors.newPassword?.message}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.confirmPassword?.message}>
            <FormLabel>Confirm Password</FormLabel>
            <Input type='password' {...register('confirmPassword')} />
            <FormErrorMessage>{errors.confirmPassword?.message}</FormErrorMessage>

          </FormControl>
          <AsyncButton type='submit' mt='15px' variant='login'>
            Reset Password
          </AsyncButton>
        </form>
      </Flex>
      <Link as={RouterLink} to='/login' textDecoration='underline'>
        Back To Login
      </Link>
    </Flex>
  );
}
