import { theme as chakraTheme, extendTheme } from '@chakra-ui/react';

import { buttonTheme } from './button';
import { bodyFont, stylisticFont } from './constants';
import { inputTheme } from './styles/input';
import { tableTheme } from './table';
import { tabsTheme } from './tabs';

window.localStorage.removeItem('chakra-ui-color-mode');

const fonts = {
  ...chakraTheme.fonts,
  body: 'Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
  heading: 'Inter,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
};

export const newTheme = extendTheme({
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  colors: {
    red: {
      1000: '#371d25',
    },
    purple: {
      1000: '#2a2547',
    },
    teal: {
      1000: '#1c2e36',
    },
  },
  components: {
    Table: tableTheme,
    Tabs: tabsTheme,
    Button: buttonTheme,
    Input: inputTheme,
  },
  fonts: {
    heading: stylisticFont,
    body: bodyFont,
    color: 'white',
  },
  styles: {
    global: {
      body: {
        bg: '#171923',
        overflowY: 'scroll',
      },
    },
  },
});
