import axios from 'axios';
import {
  ReactNode,
  createContext, useEffect, useMemo, useState,
} from 'react';
import { useLogger } from 'react-use';

import { IUserModel } from 'api/Models';
import { AsyncWrapper } from 'components/AsyncWrapper';
import { Sentry } from 'configureSentry';

async function logIn(email: string, password: string): Promise<void> {
  const token = await axios.post<string>('/api/auth/login', {
    email,
    password,
  });
}

async function logOut(params?: URLSearchParams | string): Promise<void> {
  console.warn('sign out flow triggered...');
  await axios.post('/api/auth/logout');
  localStorage.clear();
  const urlParams = params ? `?${new URLSearchParams(params).toString()}` : '';
  window.location.href = `/login${urlParams}`;
}

export interface IUserState {
  user: IUserModel | undefined,
  isLoggedIn: boolean | undefined

  login: (email: string, password: string) => Promise<void>
  logout: () => Promise<void>
}

export const UserContext = createContext<IUserState>({
  user: undefined,
  isLoggedIn: undefined,
  login: async () => { throw new Error('login not implemented'); },
  logout: async () => { throw new Error('logout not implemented'); },
});

async function getCurrentUser(): Promise<IUserModel> {
  const resp = await axios.get<IUserModel>('/api/auth/me');
  return resp.data;
}

export default function UserContextProvider({ children }: { children: ReactNode }): JSX.Element {
  const [userInfo, setUserInfo] = useState<IUserModel>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    void getCurrentUser().then((user) => {
      setUserInfo(user);
      setIsLoading(false);
    }).catch((e: any) => {
      Sentry.captureException(e);
      setIsLoading(false);
    });
  }, []);

  const state: IUserState = useMemo(() => ({
    user: userInfo,
    isLoggedIn: !!userInfo,
    login: async (email: string, password: string) => {
      setIsLoading(true);
      try {
        await logIn(email, password);
        const user = await getCurrentUser();
        setUserInfo(user);
      } catch (e: any) {
        Sentry.captureException(e);
      } finally {
        setIsLoading(false);
      }
    },
    logout: async () => {
      setIsLoading(true);
      try {
        await logOut();
      } catch (e: any) {
        Sentry.captureException(e);
      } finally {
        setIsLoading(false);
      }
    },
  }), [userInfo]);

  useLogger('userContext');

  return (
    <AsyncWrapper requests={[{ loading: isLoading }]}>
      <UserContext.Provider value={state}>
        {children}
      </UserContext.Provider>
    </AsyncWrapper>
  );
}
