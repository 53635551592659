import {
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';

import { IDicomServerModel } from 'api/Models';
import AlertDialogFrame from 'components/dialog/AlertDialogFrame';
import { IDeleteAlertProps } from 'components/dialog/types';

export default function DeleteDicomServerAlert({
  data, isOpen, onClose,
}: IDeleteAlertProps<IDicomServerModel>): JSX.Element {
  const toast = useToast();

  const handleDelete = async (): Promise<void> => {
    await axios.delete(`/api/dicomserver/${data.id}`);
    toast({
      title: 'DICOM Server Deleted',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
    onClose();
  };

  return (
    <AlertDialogFrame
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleDelete}
      header='Delete DICOM Server'
      content={`Are you sure you want to permanently delete ${data.serverName}?`}
    />
  );
}
