import {
  Flex, Text,
} from '@chakra-ui/react';

export function OfflineAlert(): JSX.Element {
  return (
    <Flex px='54px' h='60px' w='100vw' background='orange.300' alignItems='center' justifyContent='center' gap='15px'>
      <Text color='black' fontSize='15px' fontWeight='500'>
        YOU ARE CURRENTLY OFFLINE. PLEASE CHECK YOUR INTERNET CONNECTION.
      </Text>
    </Flex>
  );
}
