import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import axios from 'axios';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import * as z from 'zod';

import { IUserModel } from 'api/Models';
import { AsyncWrapper } from 'components/AsyncWrapper';
import AsyncButton from 'components/form/AsyncButton';
import useAxios from 'hooks/useAxios';

interface IProfileData {
  firstName: string;
  lastName: string;
}

// zod will validate through type, meaning a string = "" will satisfy the validation
// to fix this, we can add a min length to the string, to ensure it will not be empty. See link to the discussion https://github.com/colinhacks/zod/issues/2466
const UserSchema = z.object({
  firstName: z.string().trim().min(1, { message: 'First Name is required' }),
  lastName: z.string().trim().min(1, { message: 'Last Name is required' }),
});

export default function EditProfile(): JSX.Element {
  const [userRequest] = useAxios<IUserModel>('/api/user');
  const toast = useToast();
  const nav = useNavigate();
  const formMethods = useForm<IProfileData>({
    resolver: zodResolver(UserSchema),
  });
  const { register, handleSubmit, formState: { errors } } = formMethods;

  async function onSubmit(data: IProfileData): Promise<void> {
    const updateUser = { ...userRequest.data, ...data };
    await axios.put('/api/user', updateUser);
    toast({
      title: 'Success',
      description: 'Profile updated successfully',
      status: 'success',
      duration: 5000,
      isClosable: true,
      position: 'top',
    });
  }

  return (
    <AsyncWrapper requests={[userRequest]}>
      <FormControl maxW={[450]}>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormLabel>Email: </FormLabel>
            <FormLabel>{userRequest.data?.email}:</FormLabel>
            <FormLabel mt='25px'>First Name:</FormLabel>
            <Input
              size='lg'
              autoFocus
              type='text'
              placeholder={userRequest.data?.firstName}
              defaultValue={userRequest.data?.firstName}
              {...register('firstName')}
            />
            {errors.firstName && <p>{errors.firstName.message}</p>}
            <FormLabel mt='25px'>Last Name:</FormLabel>
            <Input
              size='lg'
              type='text'
              placeholder={userRequest.data?.lastName}
              defaultValue={userRequest.data?.lastName}
              {...register('lastName')}
            />
            {errors.lastName && <p>{errors.lastName.message}</p>}
            <Flex gap='20px'>
              <AsyncButton mt='50px' type='submit'>
                Save changes
              </AsyncButton>
              <Button mt='50px' variant='link' type='reset' onClick={() => nav(-1)}>
                Cancel
              </Button>
            </Flex>
          </form>
        </FormProvider>
      </FormControl>
    </AsyncWrapper>
  );
}
