import {
  Avatar, Box, MenuButton, Text,
} from '@chakra-ui/react';

export function Profile({ displayName, email, avatarSrc }: { displayName?: string | null, email?: string | null, avatarSrc?: string | null | Uint8Array }): JSX.Element {
  const imageSrc = avatarSrc?.constructor === Uint8Array ? `data:image/png;base64, ${avatarSrc}` : avatarSrc;

  return (
    <MenuButton width='fit-content' height='65px' borderRadius='66px' backgroundColor='gray.800' boxShadow='0px 15px 54px rgba(0, 0, 0, 0.45)'>
      <Box display='flex' flexDir='row' justifyContent='space-between' alignItems='center' width='100%' paddingRight='9px' paddingLeft='23px'>
        <Box display='flex' fontWeight='500' flexDir='column' flexGrow={1} paddingRight='15px'>
          <Text alignSelf='flex-end' fontSize='15px' lineHeight='18px'>{displayName}</Text>
          <Text
            alignSelf='flex-end'
            as='p'
            fontSize='12px'
            lineHeight='14px'
            color='gray.500'
            display={['none', 'none', 'none', 'inline-block']}
          >{email}
          </Text>
        </Box>
        <Avatar name={displayName ?? undefined} src={imageSrc ? `${imageSrc}` : ''} boxSize='46px' />
      </Box>
    </MenuButton>
  );
}
