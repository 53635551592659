import {
  Center, Heading, Stack, Table, TableContainer, Tbody, Td, Th, Thead, Tr,
} from '@chakra-ui/react';
import {
  ColumnDef, flexRender, getCoreRowModel, useReactTable,
} from '@tanstack/react-table';
import { When } from 'react-if';
import { useLocation, useNavigate } from 'react-router';

import { SearchBar } from './SearchBar';

export default function SkylineTable<T>({
  columns,
  isSearchable,
  data,
  PaginationControl,
  emptyTableState,
  invisibleColumns,
  onRowClick,
  onRowDoubleClick,
}: {
  columns: ColumnDef<T, any>[];
  isSearchable: boolean;
  data: T[];
  PaginationControl?: JSX.Element;
  emptyTableState?: { message: string };
  invisibleColumns?:Record<string, boolean>;
  onRowClick?: (row: T) => void;
  onRowDoubleClick?: (row: T) => void;
}): JSX.Element {
  const table = useReactTable({
    data,
    getCoreRowModel: getCoreRowModel(),
    columns,
    state: {
      columnVisibility: invisibleColumns,
    },
  });

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const handleSearch = (newSearchTerm: string): void => {
    if (!newSearchTerm) {
      searchParams.delete('search');
    } else {
      searchParams.set('search', newSearchTerm.trim());
    }
    navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
  };

  return (
    <Stack gap='20px'>
      <When condition={isSearchable}>
        <SearchBar onSubmit={handleSearch} />
      </When>
      <TableContainer>
        <Table variant='simple'>
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Th key={header.id}>
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr
                key={row.id}
                cursor='pointer'
                role='group'
              >
                {row.getVisibleCells().map((cell) => (
                  <Td
                    key={cell.id}
                    onClick={cell.column.id.toLowerCase() === 'actions' ? () => {} : () => (onRowClick ? onRowClick(row.original) : {})}
                    textAlign={cell.column.id.toLowerCase() === 'actions' ? 'right' : 'left'}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
        <When condition={!!PaginationControl}>
          {PaginationControl}
        </When>
        <When condition={data.length === 0}>
          <Center flexDirection='column' gap='38px' mx='auto' mt='200px' w='400px'>
            <Heading as='h3' size='lg' textAlign='center'>{emptyTableState?.message}</Heading>
          </Center>
        </When>
      </TableContainer>
    </Stack>
  );
}
