import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Image,
  Input,
  Link,
  Text,
  useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import * as z from 'zod';

import RoundedKeyIcon from 'assets/icons/rounded-key.svg';
import AsyncButton from 'components/form/AsyncButton';

interface IRequestResetPasswordForm {
  email: string;
}

const requestResetPasswordSchema = z.object({
  email: z.string().trim().min(1, 'Email is required')
    .email('Invalid email address'),
});

export default function RequestResetPassword(): JSX.Element {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IRequestResetPasswordForm>({
    defaultValues: {
      email: '',
    },
    resolver: zodResolver(requestResetPasswordSchema),
  });
  const toast = useToast();

  async function onSubmit(data: IRequestResetPasswordForm): Promise<void> {
    await axios.post('/api/auth/request-password-reset', {
      Email: data.email,
    });

    toast({
      title: 'Success',
      description: `Email sent to '${data.email}'`,
      status: 'success',
      duration: 5000,
      position: 'top',
      isClosable: true,
    });
  }
  return (
    <Flex
      width={['320px', '400px']}
      direction='column'
      mx='auto'
      align='center'
    >
      <Image src={RoundedKeyIcon} h='70px' />
      <Flex direction='column' align='center' mt='35px' mb='50px' gap='25px'>
        <Heading fontSize='24px' fontWeight='600px'>
          Forgot Password?
        </Heading>
        <Text fontSize='16px' lineHeight='20px' fontWeight='400'>
          Enter the email you signed up with and we will send an email with
          instructions to reset your password
        </Text>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl isInvalid={!!errors.email?.message}>
            <FormLabel>Email</FormLabel>
            <Input type='email' autoFocus {...register('email')} />
            <FormErrorMessage>{errors.email?.message}</FormErrorMessage>
          </FormControl>

          <AsyncButton type='submit' mt='15px' variant='login'>
            Request Password Reset
          </AsyncButton>
        </form>
      </Flex>
      <Link as={RouterLink} to='/login' textDecoration='underline'>
        Back To Login
      </Link>
    </Flex>
  );
}
