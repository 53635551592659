import { ReactNode } from 'react';
import { When } from 'react-if';

import { isProduction } from 'env';

export function HideInProd({ children, override = false }: { children: ReactNode, override?: boolean }): JSX.Element {
  return (
    <When condition={!isProduction || override}>
      { children }
    </When>
  );
}
