import { Container, Spinner } from '@chakra-ui/react';
import axios from 'axios';
import { useEffect } from 'react';
import { useSearchParam } from 'react-use';

import { defaultSpinnerProps } from 'components/AsyncWrapper';

export default function ImpersonationPage(): JSX.Element {
  const token = useSearchParam('token');

  useEffect(() => {
    const handleImpersonation = async (): Promise<void> => {
      if (!token) {
        throw new Error('No token provided');
      }
      try {
        localStorage.clear();
        const impersonationToken = await axios.post<string>('/api/auth/impersonate', {
          token,
        });
      } catch (error) {
        throw new Error('Error impersonating user');
      }
      window.location.href = '/';
    };
    void handleImpersonation();
  }, [token]);

  return (
    <Container centerContent>
      <Spinner
        {...defaultSpinnerProps}
      />
    </Container>
  );
}
