import ReactJson from 'react-json-view';
import { useLocation } from 'react-router';
import styled from 'styled-components';

import { HideInProd } from 'components/HideInProd';

interface IErrorPage {
  title?: string;
  children?: JSX.Element | JSX.Element[] | string | Error;
}

function ErrorPage({ children, title }: IErrorPage): JSX.Element {
  const location = useLocation();

  let content = children;
  if (children instanceof Error) {
    content = <ReactJson src={children} />;
  } else if (children instanceof String) {
    content = <p>{children}</p>;
  }
  return (
    <Container>
      <>
        <h3>{title || 'Oops. Something went wrong'}</h3>
        {content}
        <HideInProd>
          <br />
          <ReactJson src={location} />
        </HideInProd>
      </>

    </Container>
  );
}
export default ErrorPage;

const Container = styled.div`
  img {
    height: 95px;
    margin-top: 100px;
    margin-bottom: 17px;
  }

  h3 {
    text-transform: capitalize;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
`;
