import {
  cleanEnv,
  str,
} from 'envalid';

export const env = cleanEnv({
  REACT_APP_ENV: process.env.REACT_APP_ENV,
  REACT_APP_SHA: process.env.REACT_APP_SHA,
  REACT_APP_VERSION: process.env.REACT_APP_VERSION,
  REACT_APP_STATSIG_KEY: process.env.REACT_APP_STATSIG_KEY,
}, {
  REACT_APP_SHA: str({}),
  REACT_APP_VERSION: str({}),
  REACT_APP_ENV: str<'develop' | 'staging' | 'prod' | 'test'>({}),
  REACT_APP_STATSIG_KEY: str({}),
});

export const isProduction = env.REACT_APP_ENV === 'prod';

console.log('ENV', {
  REACT_APP_ENV: env.REACT_APP_ENV,
  REACT_APP_SHA: env.REACT_APP_SHA,
  REACT_APP_VERSION: env.REACT_APP_VERSION,
});
