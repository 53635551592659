import { isAxiosError } from 'axios';
import { isString } from 'lodash';

import { Sentry } from 'configureSentry';
import { isProduction } from 'env';

function parseError(error: any): { message: string, code: string } {
  if (isString(error)) {
    return {
      message: error,
      code: 'TEXT_ERROR',
    };
  }

  if (isAxiosError(error)) {
    return {
      message: error.response?.data?.message || error.message,
      code: `${error.response?.status}`,
    };
  }

  return {
    message: error.message || 'Connectivity Error',
    code: error.code || 'UNKNOWN_NETWORK_ERROR',
  };
}

export function logError(error: any): { message: string, code: string } {
  Sentry.captureException(error);

  if (!isProduction) {
    console.error(error);
  }

  return parseError(error);
}
