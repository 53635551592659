import {
  AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button,
} from '@chakra-ui/react';
import { useRef } from 'react';

import AsyncButton from 'components/form/AsyncButton';

interface IAlertDialogFrameProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  content: string;
  header: string;
  actionLabel?: string;
}

export default function AlertDialogFrame({
  isOpen, onClose, onSubmit, content, header, actionLabel = 'Delete',
}: IAlertDialogFrameProps): JSX.Element {
  const cancelRef = useRef<HTMLButtonElement>(null);

  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            {header}
          </AlertDialogHeader>
          <AlertDialogBody>
            {content}
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <AsyncButton colorScheme='red' onClick={onSubmit} ml={3}>
              {actionLabel}
            </AsyncButton>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}
