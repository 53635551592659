import { SearchIcon } from '@chakra-ui/icons';
import {
  IconButton, Image, Input, InputGroup, InputLeftElement,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router';

import CascadeIcon from 'assets/icons/cascade.svg';
import { borderRadius, skylineColor } from 'themes/constants';

export function SearchBar({ onSubmit }: { onSubmit: (searchTerm: string) => void }): JSX.Element {
  const { search } = useLocation();
  const { register, handleSubmit } = useForm<{ searchTerm: string }>({
    defaultValues: {
      searchTerm: new URLSearchParams(search).get('search') ?? '',
    },
  });

  const submitHandler = handleSubmit(({ searchTerm }: { searchTerm: string }) => {
    onSubmit(searchTerm.trim());
  });

  return (
    <InputGroup
      gap='2'
      maxWidth='500px'
      height='50px'
    >
      <InputLeftElement
        pointerEvents='none'
        transform='none'
      >
        <Image color='gray.400' w='20px' h='20px' src={CascadeIcon} />
      </InputLeftElement>
      <Input
        border='1px solid'
        borderColor='gray.500'
        borderRadius={borderRadius}
        type='text'
        focusBorderColor={skylineColor}
        {...register('searchTerm')}
        placeholder='Search...'
        _placeholder={{ color: 'gray.400' }}
        onKeyDown={(event) => (event.key === 'Enter' ? submitHandler : () => {})()}
      />
      <IconButton
        height='55px'
        width='55px'
        backgroundColor='gray.800'
        aria-label='Search'
        icon={<SearchIcon />}
        onClick={submitHandler}
        borderRadius={borderRadius}
      />
    </InputGroup>
  );
}
