import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Image,
  Input,
  Text,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import Warning from 'assets/icons/warning.png';
import AsyncButton from 'components/form/AsyncButton';

interface IPasswordReset {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export default function ChangePassword(): JSX.Element {
  const formMethods = useForm<IPasswordReset>();
  const {
    register,
    handleSubmit,
    formState,
    setError,
    reset,
  } = formMethods;
  const { errors } = formState;
  const toast = useToast();
  const nav = useNavigate();

  function showSuccessToast(): void {
    toast({
      title: 'Success',
      description: 'Password updated',
      status: 'success',
      duration: 5000,
      position: 'top',
      isClosable: true,
    });
  }

  // wip, still will reset password so use at own risk
  // test
  async function onSubmit({
    currentPassword,
    newPassword,
    confirmPassword,
  }: IPasswordReset): Promise<void> {
    await axios.post('/api/auth/change-password', {
      currentPassword,
      newPassword,
      confirmPassword,
    });
    showSuccessToast();
    reset();
  }

  return (
    <FormControl isInvalid={!!errors.confirmPassword?.message} maxW={[450]}>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormLabel>Current password:</FormLabel>
          <Input
            size='lg'
            autoFocus
            type='password'
            {...register('currentPassword')}
          />
          <FormLabel mt='25px'>New password:</FormLabel>
          <Input size='lg' type='password' {...register('newPassword')} />
          <FormLabel mt='25px'>Confirm password:</FormLabel>
          <Input size='lg' type='password' {...register('confirmPassword')} />
          <FormErrorMessage mt='10px' gap='10px'>
            <Image src={Warning} boxSize='15px' />
            <Text pt='5px'>{errors.confirmPassword?.message} </Text>
          </FormErrorMessage>
          <Flex gap='20px'>
            <AsyncButton
              mt='50px'
              type='submit'
              formState={formState}
              onClick={handleSubmit(onSubmit)}
            >
              Save changes
            </AsyncButton>
            <Button
              mt='50px'
              variant='link'
              type='reset'
              onClick={() => nav(-1)}
            >
              Cancel
            </Button>
          </Flex>
        </form>
      </FormProvider>
    </FormControl>
  );
}
