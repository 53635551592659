import { AddIcon } from '@chakra-ui/icons';
import { Button, Stack } from '@chakra-ui/react';
import {
  createColumnHelper,
} from '@tanstack/react-table';
import { useMemo } from 'react';
import { When } from 'react-if';

import DeleteDicomServerAlert from './components/DeleteDicomServerAlert';
import DicomServerModal from './components/DicomServerModal';
import { IDicomServerModel } from 'api/Models';
import { ActionMenu } from 'components/ActionMenu';
import { AsyncWrapper } from 'components/AsyncWrapper';
import SkylineTable from 'components/table/SkylineTable';
import useAxios from 'hooks/useAxios';
import { useModal } from 'hooks/useModal';

export default function DicomServersList(): JSX.Element {
  const [rows, refetchRows] = useAxios<IDicomServerModel[]>('/api/dicomserver');

  const [
    { isOpen: isDicomModalOpen, data: dicomServerData },
    {
      onOpen: onDicomModalOpen,
      handleEdit: handleEditDicom,
      onClose: onCloseDicomModal,
    },
  ] = useModal<IDicomServerModel>({
    onClose: () => void refetchRows(),
  });

  const [
    { isOpen: isDeleteServerAlertOpen, data: severDeleteData },
    {
      handleDelete: handleDeleteServer,
      onClose: onCloseServerAlert,
    },
  ] = useModal<IDicomServerModel>({
    onClose: () => void refetchRows(),
  });

  const columnHelper = createColumnHelper<IDicomServerModel>();

  const columns = useMemo(() => [
    columnHelper.accessor('serverName', {
      header: () => 'Name',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('host', {
      header: () => 'Host',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('port', {
      header: () => 'Port',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('aeTitle', {
      header: () => 'AE Title',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor('tls', {
      header: () => 'TLS',
      cell: (info) => (info.getValue() ? 'Yes' : 'No'),
    }),
    columnHelper.display(
      {
        id: 'actions',
        cell: ({ row }) => (() => (
          <ActionMenu
            {...[
              { label: 'Edit', action: () => handleEditDicom(row.original) },
              { label: 'Delete', action: () => handleDeleteServer(row.original) },
            ]}
          />
        ))(),
      },
    ),
  ], [columnHelper, handleDeleteServer, handleEditDicom]);

  return (
    <AsyncWrapper requests={[{ loading: !rows }]}>
      <Stack>
        <Button onClick={onDicomModalOpen} aria-label='Add new DICOM server' leftIcon={<AddIcon />} marginLeft='auto'>Add new server</Button>
        <SkylineTable<IDicomServerModel>
          data={rows?.data ?? []}
          columns={columns}
          isSearchable={false}
          emptyTableState={{ message: 'No DICOM servers found for this clinic' }}
        />
        <When condition={isDicomModalOpen}>
          <DicomServerModal onClose={onCloseDicomModal} isOpen={isDicomModalOpen} serverData={dicomServerData} />
        </When>
        <When condition={isDeleteServerAlertOpen}>
          <DeleteDicomServerAlert
            isOpen={isDeleteServerAlertOpen}
            onClose={onCloseServerAlert}
            data={severDeleteData!}
          />
        </When>
      </Stack>
    </AsyncWrapper>
  );
}
