import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import axios from 'axios';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import * as z from 'zod';

import { IClinicModel } from 'api/Models';
import { AsyncWrapper } from 'components/AsyncWrapper';
import AsyncButton from 'components/form/AsyncButton';
import useAxios from 'hooks/useAxios';

interface IClinicForm {
  name: string;
  address: string;
  email: string;
  phone: string;
}

const clinicSchema = z.object({
  name: z.string().trim().min(1, { message: 'Clinic name required' }),
  address: z.string().trim().min(1, { message: 'Address is required' }),
  email: z.string().trim().email({ message: 'Invalid email format' }),
  phone: z.string().trim().regex(/^(?:(?:\+\d{1,2}\s?)?(?:\(\d{3}\)|\d{3})[\s-]?\d{3}[\s-]?\d{4})$/, { message: 'Invalid phone number format' }),
});

export default function EditClinic(): JSX.Element {
  const [clinicRequest] = useAxios<IClinicModel>('/api/clinic');
  const nav = useNavigate();
  const toast = useToast();
  const formMethods = useForm<IClinicForm>({
    resolver: zodResolver(clinicSchema),
  });
  const { register, handleSubmit, formState: { errors } } = formMethods;

  async function onSubmit(data: IClinicForm): Promise<void> {
    const updateClinic = { ...clinicRequest.data, ...data };
    await axios.put('/api/clinic', updateClinic);
    toast({
      title: 'Success',
      description: 'Clinic updated successfully',
      status: 'success',
      duration: 5000,
      isClosable: true,
      position: 'top',
    });
  }

  return (
    <AsyncWrapper requests={[clinicRequest]}>
      <FormControl maxW='450px'>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormLabel>Clinic Name:</FormLabel>
            <Input
              size='lg'
              type='text'
              {...register('name')}
              placeholder='Clinic Name'
              defaultValue={clinicRequest.data?.name}
            />
            {errors.name && <p>{errors.name.message}</p>}
            <FormLabel mt='25px'>Clinic Address:</FormLabel>
            <Input
              size='lg'
              type='text'
              {...register('address')}
              placeholder='Clinic Address'
              defaultValue={clinicRequest.data?.address}
            />
            {errors.address && <p>{errors.address.message}</p>}
            <FormLabel mt='25px'>Clinic Email:</FormLabel>
            <Input
              size='lg'
              type='email'
              {...register('email')}
              placeholder='Clinic Email'
              defaultValue={clinicRequest.data?.email}
            />
            {errors.email && <p>{errors.email.message}</p>}
            <FormLabel mt='25px'>Clinic Phone:</FormLabel>
            <Input
              size='lg'
              type='tel'
              {...register('phone')}
              placeholder='Clinic Phone'
              defaultValue={clinicRequest.data?.phone}
            />
            {errors.phone && <p>{errors.phone.message}</p>}
            <Flex gap='20px'>
              <AsyncButton
                mt='50px'
                type='submit'
                onClick={handleSubmit(onSubmit)}
              >
                Save changes
              </AsyncButton>
              <Button
                mt='50px'
                variant='link'
                type='reset'
                onClick={() => nav(-1)}
              >
                Cancel
              </Button>
            </Flex>
          </form>
        </FormProvider>
      </FormControl>
    </AsyncWrapper>
  );
}
