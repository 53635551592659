import { Stack, Text } from '@chakra-ui/react';
import {
  createColumnHelper,
} from '@tanstack/react-table';
import { useMemo } from 'react';
import { When } from 'react-if';

import { DeleteStudyAlert } from './components/DeleteStudyAlert';
import { ClinicType, IStudieModel } from 'api/Models';
import { AsyncWrapper } from 'components/AsyncWrapper';
import ImagesPreview from 'components/table/ImagesPreview';
import SkylineTable from 'components/table/SkylineTable';
import { useModal } from 'hooks/useModal';
import { usePagedData } from 'hooks/usePagedData';
import useUserContext from 'hooks/useUserContext';
import { getDate } from 'utils/formatter';
import { getPatientName } from 'utils/name';

function Studylist(): JSX.Element {
  const { user } = useUserContext();
  const isHumanClinic = user?.clinic?.clinicType === ClinicType.Human;
  const {
    request, PaginationControl, refetchRequest,
  } = usePagedData<IStudieModel>({
    path: '/api/study/studylist',
  });

  const [
    {
      isOpen: isDeleteStudyAlertOpen,
      data: deleteStudyData,
    },
    {
      handleDelete: handleDeleteStudy,
      onClose: onCloseDeleteStudyAlert,
    },
  ] = useModal<IStudieModel>({
    onClose: () => refetchRequest(),
  });

  const columnHelper = createColumnHelper<IStudieModel>();

  const openOhifViewer = (row: IStudieModel): void => {
    const { id } = row;
    const url = `/viewer/skyline/dicomjson?url=/api/ohif/${id}`;
    window.open(url, '_blank');
  };

  const columns = useMemo(() => [
    columnHelper.accessor((row) => row.patient?.patientId ?? 'Unknown', {
      id: 'patientId',
      header: () => 'Patient ID',
      cell: (info) => info.getValue().toString(),
    }),
    columnHelper.accessor('patient.name', {
      header: () => 'Name',
      cell: ({ row }) => (() => (
        <Stack>
          <Text>
            {getPatientName(row.original.patient)}
          </Text>
          <When condition={row.original.patient?.responsiblePerson}>
            <Text>{row.original.patient?.responsiblePersonRole}: {row.original.patient?.responsiblePerson}</Text>
          </When>
        </Stack>
      ))(),
    }),
    columnHelper.accessor((row) => getDate(row.studyDateTime || ''), {
      id: 'studyDateTime',
      header: () => 'Date',
      cell: (info) => info.getValue(),
    }),
    columnHelper.accessor((row) => row.images.length.toString(), {
      id: 'numImages',
      header: () => 'Images',
      cell: (info) => info.getValue(),
    }),
    columnHelper.display({
      id: 'ImagesPreview',
      header: () => 'Preview',
      cell: ({ row }) => (() => (
        <ImagesPreview images={row.original.images} />
      ))(),
    }),
    // Disabling delete until we can audit
    // columnHelper.display(
    //   {
    //     id: 'actions',
    //     cell: ({ row }) => (() => (
    //       <ActionMenu
    //         {
    //         ...[
    //           { label: 'Delete', action: () => handleDeleteStudy(row.original) },
    //         ]
    //         }
    //       />
    //     ))(),
    //   },
    // ),
  ], [columnHelper]);

  const invisibleColumns = useMemo(() => {
    if (isHumanClinic) return { responsiblePerson: false };
  }, [isHumanClinic]);

  return (
    <AsyncWrapper requests={[{ loading: !!request?.loading }]}>
      <SkylineTable<IStudieModel>
        data={request?.data?.records || []}
        columns={columns}
        isSearchable
        invisibleColumns={invisibleColumns}
        PaginationControl={PaginationControl}
        emptyTableState={{ message: 'No studies found for this clinic' }}
        onRowClick={openOhifViewer}
      />
      <When condition={isDeleteStudyAlertOpen}>
        <DeleteStudyAlert
          isOpen={isDeleteStudyAlertOpen}
          onClose={onCloseDeleteStudyAlert}
          data={deleteStudyData!}
        />
      </When>
    </AsyncWrapper>
  );
}

export default Studylist;
