import {
  Button, Checkbox, Flex, FormControl, FormErrorMessage, FormLabel, Input, Text,
  useToast,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

import AsyncButton from 'components/form/AsyncButton';
import { ModalFrame } from 'components/modal/ModalFrame';

interface IDicomServerForm {
  serverName: string;
  host: string;
  port: number;
  aeTitle: string;
  tls: boolean;
}

const dicomServerSchema = z.object({
  serverName: z.string().trim().min(1, { message: 'Server name required' }),
  host: z.string().trim().min(1, { message: 'Host is required' }),
  port: z.coerce.number({
    required_error: 'Port must be a number',
    invalid_type_error: 'Port must be a number',
  }).positive({ message: 'Port must be a positive number' }),
  aeTitle: z.string().trim(),
  tls: z.boolean(),
});

export default function DicomServerModal({ isOpen, onClose, serverData }: { isOpen: boolean, onClose: () => void, serverData?: IDicomServerForm }): JSX.Element {
  const toast = useToast();
  const isCreate = !serverData;

  const formMethods = useForm<IDicomServerForm>({
    resolver: zodResolver(dicomServerSchema),
  });
  const { register, handleSubmit, formState: { errors } } = formMethods;

  const onSubmit = async (input: IDicomServerForm): Promise<void> => {
    if (isCreate) {
      await axios.post('/api/dicomserver/create', input);
    } else {
      await axios.put('/api/dicomserver', { ...serverData, ...input });
    }
    toast({
      title: 'Success',
      description: isCreate ? 'DICOM added successfully' : 'DICOM updated successfully',
      status: 'success',
      duration: 5000,
      isClosable: true,
      position: 'top',
    });
    onClose();
  };

  const Header = (<Text> {isCreate ? 'Add DICOM Server' : 'Edit DICOM Server'}</Text>);

  return (
    <ModalFrame
      header={Header}
      isOpen={isOpen}
      onClose={onClose}
      hasCloseButton
    >
      <FormControl isInvalid={!!errors.port}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormLabel>Server Name</FormLabel>
          <Input
            {...register('serverName')}
            defaultValue={serverData?.serverName}
            isRequired
          />
          <FormErrorMessage>{errors.serverName?.message}</FormErrorMessage>
          <FormLabel>Host</FormLabel>
          <Input
            {...register('host')}
            defaultValue={serverData?.host}
            isRequired
          />
          <FormErrorMessage>{errors.host?.message}</FormErrorMessage>
          <FormLabel>Port</FormLabel>
          <Input
            {...register('port')}
            type='number'
            min={1}
            max={14000}
            defaultValue={serverData?.port}
            isRequired
          />
          <FormErrorMessage>{errors.port?.message}</FormErrorMessage>
          <FormLabel>AE Title</FormLabel>
          <Input
            {...register('aeTitle')}
            defaultValue={serverData?.aeTitle}
            minLength={3}
            maxLength={14}
            isRequired
          />
          <FormErrorMessage>{errors.aeTitle?.message}</FormErrorMessage>
          <FormLabel>Use TLS</FormLabel>
          <Checkbox
            {...register('tls')}
            defaultChecked={serverData?.tls}
          />
          <FormErrorMessage>{errors.tls?.message}</FormErrorMessage>

          <Flex gap='20px'>
            <AsyncButton
              type='submit'
            >
              Save changes
            </AsyncButton>
            <Button
              type='reset'
              onClick={onClose}
            >
              Cancel
            </Button>
          </Flex>
        </form>
      </FormControl>
    </ModalFrame>
  );
}
