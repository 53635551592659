import { useToast } from '@chakra-ui/react';
import axios from 'axios';

import { IStudieModel } from 'api/Models';
import AlertDialogFrame from 'components/dialog/AlertDialogFrame';
import { IDeleteAlertProps } from 'components/dialog/types';
import { getDate } from 'utils/formatter';

export function DeleteStudyAlert({
  data,
  isOpen,
  onClose,
}: IDeleteAlertProps<IStudieModel>): JSX.Element {
  const toast = useToast();

  const handleDeleteStudy = async (): Promise<void> => {
    await axios.delete(`/api/study/${data.id}`);
    toast({
      title: 'Study Deleted',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
    onClose();
  };

  return (
    <AlertDialogFrame
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleDeleteStudy}
      header='Delete Study'
      content={
      `Are you sure you want to permanently delete this study
          and its ${data.images.length} images for ${data.patient?.name}
          from ${getDate(data.createdDate)}?`
        }
    />
  );
}
