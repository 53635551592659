import {
  FormControl, FormErrorMessage, Heading, Image, Input, InputGroup, InputLeftElement, Link, Text,
} from '@chakra-ui/react';
import {
  FormProvider, UseFormSetError, useForm,
} from 'react-hook-form';
import { When } from 'react-if';
import { Link as RouterLink } from 'react-router-dom';
import { useEffectOnce, useSearchParam } from 'react-use';

import EmailIcon from 'assets/icons/email.svg';
import LockIcon from 'assets/icons/lock.svg';
import RightArrowIcon from 'assets/icons/right-arrow.svg';
import WarningIcon from 'assets/icons/warning.svg';
import AsyncButton from 'components/form/AsyncButton';

export interface ILogin {
  email: string,
  password: string
}

export function CredentialsForm({ submitCallback }: {
  submitCallback?: (data: ILogin, setError: UseFormSetError<ILogin>) => Promise<void>;
}): JSX.Element {
  const formMethods = useForm<ILogin>();

  const {
    register, handleSubmit, formState, setError,
  } = formMethods;
  const { errors, isValid } = formState;

  const emailAddress = useSearchParam('email');

  useEffectOnce(() => {
    if (emailAddress) {
      formMethods.setValue('email', emailAddress);
      const passwordField = document.getElementById('password');
      if (passwordField) {
        passwordField.focus();
      }
    }
  });

  async function onSubmit(data: ILogin): Promise<void> {
    if (data && isValid && submitCallback) {
      await submitCallback(data, setError);
    }
  }

  return (
    <>
      <Heading fontSize='24px' fontWeight='600' mb='30px'>Sign in</Heading>
      <FormControl isInvalid={!!errors.password?.message || !!errors.email?.message}>
        <FormProvider {...formMethods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <InputGroup>
              <InputLeftElement pointerEvents='none'>
                <Image src={EmailIcon} w='10px' h='10px' />
              </InputLeftElement>
              <Input
                id='email'
                mb='10px'
                autoFocus
                autoComplete='email'
                placeholder='Email'
                {...register('email', { required: 'Email is required' })}
              />
            </InputGroup>
            <InputGroup>
              <InputLeftElement pointerEvents='none'>
                <Image src={LockIcon} w='10px' h='10px' />
              </InputLeftElement>
              <Input
                id='password'
                autoComplete='password'
                placeholder='Password'
                type='password'
                {...register('password', { required: 'Password is required' })}
              />
            </InputGroup>
            <When condition={!!errors.email}>
              <FormErrorMessage mt='10px' gap='10px'>
                <Image src={WarningIcon} w='11px' h='10px' />
                <Text pt='5px' textColor='red.500'>{errors.email?.message} </Text>
              </FormErrorMessage>
            </When>
            <When condition={!!errors.password}>
              <FormErrorMessage mt='10px' gap='10px'>
                <Image src={WarningIcon} w='11px' h='10px' />
                <Text pt='5px' textColor='red.500'>{errors.password?.message} </Text>
              </FormErrorMessage>
            </When>
            <AsyncButton
              variant='login'
              type='submit'
              formState={formState}
              onClick={handleSubmit(onSubmit)}
            >
              Sign in
              <Image w='13px' h='10px' src={RightArrowIcon} />
            </AsyncButton>
          </form>
        </FormProvider>
      </FormControl>
      <Link fontWeight='400' fontSize='14px' mt='50px' as={RouterLink} to='/request-reset-password'> Forgot your password? Click &#32;
        <Text as='span' fontWeight='700'>
          here.
        </Text>
      </Link>
    </>
  );
}
