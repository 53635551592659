import { tabsAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { skylineColor, stylisticFont } from './constants';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tabsAnatomy.keys);

const line = definePartsStyle(() => ({
  tab: {
    px: '30px',
    fontSize: '14px',
    fontWeight: '700',
    fill: 'white',
    _selected: {
      color: skylineColor,
      fill: skylineColor,
    },
    pb: '21px',
  },
  tabpanel: {
    padding: '25px 0px',
  },
  tablist: {
    borderColor: 'gray.400',
  },
}));

export const tabsTheme = defineMultiStyleConfig({
  variants: {
    line,
  },
  baseStyle: {
    tab: {
      fontFamily: stylisticFont,
    },
  },
});
