/* eslint-disable no-restricted-imports */
import {
  captureConsoleIntegration,
  contextLinesIntegration,
  dedupeIntegration,
  extraErrorDataIntegration,
  httpClientIntegration,
} from '@sentry/integrations';
import * as SentryReact from '@sentry/react';
import {
  BrowserOptions,
  browserTracingIntegration,
  replayIntegration,
} from '@sentry/react';

import { env } from 'env';
import { StudioError } from 'StudioError';

const options: BrowserOptions = {
  // dsn: 'https://7c0e2ab5013c47669fd05ad5c9d68fba@o4504777948659712.ingest.sentry.io/4504778004824064',
  environment: env.REACT_APP_ENV,
  release: env.REACT_APP_VERSION,
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: env.REACT_APP_ENV === 'develop' ? 0 : 1.0,
  integrations: [
    contextLinesIntegration(),
    dedupeIntegration(),
    browserTracingIntegration(),
    captureConsoleIntegration({
      levels: ['error'],
    }),
    extraErrorDataIntegration(),
    httpClientIntegration(),
    replayIntegration({
      maskAllInputs: false,
      maskAllText: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [
        window.location.origin,
        'https://transfers-studium33-dev.azurewebsites.net',
        'https://studio-server-studium33-dev.azurewebsites.net',
        'https://certified-transfer-studium33-prod.azurewebsites.net',
      ],
    }),
  ],
  beforeSend(event, hint) {
    const exception = hint.originalException;

    if (exception instanceof Error) {
      const fingerPrint:string[] = [];
      const pureStack = exception.stack?.split('\n').slice(1).join('\n')
        .trim();

      event.fingerprint = [
        exception.name,
        exception.constructor.name,
        exception.code || '',
        pureStack || '',
      ];

      if (exception instanceof StudioError) {
        if (exception.options?.skipSentry === true) {
          return null;
        }
      }

      event.fingerprint = fingerPrint;
    }

    return event;
  },
};

export const Sentry = SentryReact;

export function init(): void {
  Sentry.init(options);
  Sentry.setTag('sha', env.REACT_APP_SHA.substring(0, 7));
}
