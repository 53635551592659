import {
  FormControl,
  FormLabel,
  Input,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { IPacsInfoModel } from 'api/Models';
import { AsyncWrapper } from 'components/AsyncWrapper';
import useAxios from 'hooks/useAxios';

interface IClinicForm {
  PacsReceiverHost: string;
}

export default function PacsInfo(): JSX.Element {
  const [PacsInfoRequest] = useAxios<IPacsInfoModel>('/api/clinic/pacs-info');
  const nav = useNavigate();
  const formMethods = useForm<IClinicForm>({
  });

  return (
    <AsyncWrapper requests={[PacsInfoRequest]}>
      <FormControl maxW='450px'>
        <form>
          <FormLabel>Host:</FormLabel>
          <Input
            size='lg'
            type='text'
            defaultValue={PacsInfoRequest.data?.pacsReceiverHost}
            readOnly
          />
          <FormLabel>Port:</FormLabel>
          <Input
            size='lg'
            type='text'
            defaultValue={PacsInfoRequest.data?.pacsReceiverPort}
            readOnly
          />
          <FormLabel>AE Title:</FormLabel>
          <Input
            size='lg'
            type='text'
            defaultValue={PacsInfoRequest.data?.aeTitle}
            readOnly
          />
        </form>
      </FormControl>
    </AsyncWrapper>
  );
}
